import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";

import SEO from "../../components/seo";

const pillars = [
  {
    name: "Elderly",
    description:
      "SWA runs many programmes to bring joy and happiness for our seniors. This includes annual Chinese New Year lunch and NDP celebrations with Ren-Ci Community Hospital",
    imageSrc:
      "https://res.cloudinary.com/dkm14ge3g/image/upload/v1637219230/workPage/vjfgzw3qs7muqadgazs9.jpg",
    imageAlt: "Lunar New Year Lunch",
  },
  {
    name: "Community",
    description:
      "The general community also benefited from our Singapore VisionCare Programme and also our newly launched SWA Laughter Yoga Wellness Club",
    imageSrc:
      "https://res.cloudinary.com/dkm14ge3g/image/upload/v1637219132/workPage/xfpsu6wvrdmooxhsoi20.jpg",
    imageAlt: "Singapore Vision Care Programme",
  },
  {
    name: "Women",
    description:
      "SWA aim to build and support women for the future. We believe that by providing opportunities and guidance, we can help create a generation of young ladies who are beautiful in and out. ",
    imageSrc:
      "https://res.cloudinary.com/dkm14ge3g/image/upload/v1637219507/workPage/g9ktzojftdz5yoad8sdl.jpg",
    imageAlt: "FAWA",
  },
  // {
  //   name: "Diversity",
  //   description:
  //     "The success of SWA is due to the many ladies who have served the association throughout the years. SWA also helped in hosting FAWA Convention that brings 200 women leaders from 11 Asian countries to discuss and works towards better quality of life ",
  //   imageSrc:
  //     "https://res.cloudinary.com/dkm14ge3g/image/upload/v1637219507/workPage/g9ktzojftdz5yoad8sdl.jpg",
  //   imageAlt: "FAWA",
  // },
];

export default function Work() {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="SWA's projects and contributions"
          description="For the last 50 years, the Singapore Women's Association had
                  been rendering voluntary services with the aim of helping to
                  make Singapore a peaceful, happy and prosperous home for
                  Singaporeans."
        />
        <section className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div
                className="relative h-full mx-auto text-lg max-w-prose"
                aria-hidden="true"
              >
                <svg
                  className="absolute transform translate-x-32 top-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                  />
                </svg>
                <svg
                  className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute transform translate-x-32 bottom-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                  />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-lg max-w-prose">
                <h1>
                  <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-swa-1 sm:text-4xl">
                    Our Work
                  </span>
                </h1>
                <div className="my-5 aspect-w-16 aspect-h-9">
                  <iframe
                    src={`https://www.youtube.com/embed/X19wDHs1Yy4`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                    className="p-2 mr-3 border border-gray-500"
                  />
                </div>
                {/* <div className="inline-block rounded-md shadow">
                  <Link
                    to="/participate/sponsor"
                    className="flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                  >
                    Sponsorship opportunities
                  </Link>
                </div> */}
                <p className="mt-8 text-lg leading-8 text-gray-600">
                  For the last 50 years, the Singapore Women's Association had
                  been rendering voluntary services with the aim of helping to
                  make Singapore a peaceful, happy and prosperous home for
                  Singaporeans. They had initiated several programmes and
                  undertaken many projects to achieve the aim of creating a
                  better quality of life for everyone.
                </p>
              </div>
              <div className="mx-auto mt-6 prose prose-lg text-gray-600 prose-indigo">
                <p>Some of them are:</p>
                <ul>
                  <li className="list-none">
                    Ensuring better living for slum and kampong dwellers through
                    proper use of the modern amenities and facilities provided
                    by the Housing Development Board.
                  </li>
                  <li className="list-none">
                    Promoting understanding, tolerance and co- operation among
                    the various races in Singapore.
                  </li>
                  <li className="list-none">
                    Encouraging the wise choice of some of the Asian values and
                    customs for retention, and the selective adoption of modern,
                    western cultures.
                  </li>
                  <li className="list-none">
                    Promoting closer relationships among members of the family.
                  </li>
                  <li className="list-none">
                    Assisting the authorities to reduce crime, delinquency and
                    supporting community projects.
                  </li>
                  <li className="list-none">
                    Rendering services to the various sectors of the community
                    like the senior citizens, youth, women and the handicapped,
                    the sick and underprivileged.
                  </li>
                  <li className="list-none">
                    Fostering good international relations especially among
                    women.
                  </li>
                </ul>

                <h2 className="text-3xl font-bold text-center text-swa-1">
                  Mission Statement
                </h2>
                <p className="mt-8 text-lg leading-8 text-gray-600">
                  A non-governmental and non profit organisation commited to
                  actively:
                </p>
                <ul>
                  <li className="list-none">
                    Empower women, children, and seniors through holistic
                    support, education, health promotion, and community
                    engagement, fostering fellowship and cultural enrichment.
                  </li>
                  {/* <li className="list-none">
                    Enhance better health, livelihood and social status for
                    women and children
                  </li>
                  <li className="list-none">
                    Promote fellowship, cultural interests and participation in
                    the community acitivites locally and globally
                  </li> */}
                </ul>
                <h2 className="text-3xl font-bold text-center text-swa-1">
                  3 Pillars
                </h2>
                <p className="mt-8 text-lg leading-8 text-gray-600">
                  Our projects and activities are guided by 3 key pillars -
                  Elderly, Community and Diversity. We run various recurring
                  programmes that benefit our seniors, the general community and
                  building towards greater diversity.
                </p>
                <div className="max-w-2xl px-4 mx-auto lg:max-w-none ">
                  <div>
                    {pillars.map(pillar => (
                      <div
                        key={pillar.name}
                        className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center"
                      >
                        <div className="mt-6 lg:mt-0 lg:col-span-7 xl:col-span-6">
                          <p className="text-2xl font-bold text-swa-1">
                            {pillar.name}
                          </p>
                          <p className="mt-2 text-gray-500 ">
                            {pillar.description}
                          </p>
                        </div>
                        <div className=" lg:col-span-5 xl:col-span-6">
                          <div className="overflow-hidden aspect-w-5 aspect-h-3">
                            <img
                              src={pillar.imageSrc}
                              alt={pillar.imageAlt}
                              className="object-cover object-center"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <h2 className="text-3xl font-bold text-center text-swa-1">
                    Learn more about our projects
                  </h2>
                  <div className="overflow-hidden bg-white shadow sm:rounded-md">
                    <ul className="divide-y divide-gray-200">
                      <div className="px-4 py-4 sm:px-6">
                        <Link to="/how/svp">
                          Singapore VisionCare Programme
                        </Link>
                      </div>
                      <div className="px-4 py-4 sm:px-6">
                        <Link to="/how/newyearlunch">
                          Lunar New Year Lunch for elderly
                        </Link>
                      </div>
                      <div className="px-4 py-4 sm:px-6">
                        <Link to="/how/rebuild">
                          SWA Symrise Project Rebuild
                        </Link>
                      </div>
                      <div className="px-4 py-4 sm:px-6">
                        <Link to="/how/takingcharge">
                          Project Taking Charge
                        </Link>
                      </div>
                      <div className="px-4 py-4 sm:px-6">
                        <Link to="/how/renci">Ren Ci Community Hospital</Link>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="px-4 py-8 mx-auto my-12 border border-gray-300 rounded-lg max-w-7xl sm:px-6">
                <h2 className="text-2xl font-bold text-gray-900 sm:text-4xl">
                  <span className="block">
                    Is your organisation looking to support and sponsor our
                    initiatives?
                  </span>
                  <span className="block text-swa-1">Let's discuss.</span>
                </h2>
                <div className="flex mt-8">
                  <div className="inline-flex rounded-md shadow">
                    <Link
                      to="/forms/contact"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="px-4 py-8 mx-auto my-12 border border-gray-300 rounded-lg max-w-7xl sm:px-6">
                <h2 className="text-2xl font-bold text-gray-900 sm:text-4xl">
                  <span className="block">
                    Looking for volunteer opportunities?
                  </span>
                </h2>
                <div className="flex mt-8 ">
                  <div className="inline-flex rounded-md shadow">
                    <Link
                      to="/participate/volunteer"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                    >
                      Get started
                    </Link>
                  </div>
                  <div className="inline-flex ml-3">
                    <Link
                      to="/forms/volunteer"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium bg-indigo-100 border border-transparent rounded-md text-swa-3 hover:bg-swa-4"
                    >
                      Apply
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}
